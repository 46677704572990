import React, { useCallback } from 'react'
import { useSafeTrack } from '@/utils/analytics'
import { SocialSharing } from './SocialSharing'

interface SharingBlockProps {
  projectSlug: string
  viewImpact?: () => void
  showImpact?: boolean
  shareUrl: string
}

export const SharingBlock: React.FC<SharingBlockProps> = ({ projectSlug, shareUrl }) => {
  const track = useSafeTrack()

  const trackShare = useCallback(
    (network: string) => {
      track('Project Share Clicked', {
        type: network,
        projectSlug: projectSlug,
        shareURl: shareUrl,
      })
    },
    [track, projectSlug, shareUrl],
  )

  return (
    <div className="flex flex-col gap-4 text-center">
      <div className="">
        <SocialSharing
          color="gray-950"
          onShare={trackShare}
          projectSlug={projectSlug}
          url={`https://www.angel.com${shareUrl}`}
        />
      </div>
    </div>
  )
}
