import { getHexColor, OneColorIconProps } from '../utils'

export const PointerIcon: React.FC<OneColorIconProps> = ({ color = '#3B3C3C', size = 24, className, ...rest }) => {
  const c = getHexColor(color)

  return (
    <svg
      data-testid="pointer-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      fill="none"
    >
      <path
        d="M5 5L10.8326 19L12.9034 12.9034L19 10.8326L5 5Z"
        stroke={c}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.25 13.25L18.1999 18.1999" stroke={c} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
