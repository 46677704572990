import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const LinkIcon: React.FC<OneColorIconProps> = ({ size = 16, color = 'black', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      data-testid="link-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.87842 10.1208L10.1211 5.87817"
        stroke={hex}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06023 11.1818L7.29247 12.9496C6.72982 13.5121 5.96681 13.828 5.17125 13.8279C4.37569 13.8279 3.61273 13.5118 3.05019 12.9493C2.48764 12.3867 2.17158 11.6237 2.17151 10.8282C2.17144 10.0326 2.48738 9.26962 3.04983 8.70697L4.81759 6.93921"
        stroke={hex}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1818 9.06035L12.9496 7.29259C13.5121 6.72995 13.828 5.96693 13.8279 5.17137C13.8279 4.37581 13.5118 3.61286 12.9493 3.05031C12.3867 2.48776 11.6238 2.1717 10.8282 2.17163C10.0326 2.17156 9.26962 2.4875 8.70698 3.04995L6.93921 4.81771"
        stroke={hex}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
