import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { MovieSpecialIcon } from '@/atoms/Icons/MovieSpecialIcon'
import { PayItForwardOutlineIcon } from '@/atoms/Icons/PayItForwardOutlineIcon'
import { PointerIcon } from '@/atoms/Icons/PointerIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { AsH2, AsH3, TitleMD, ParagraphMD } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useUser } from '@/services/UserService'
import { UserTicketImpactQuery } from '@/types/codegen-federation'
import { useLoginUrl } from '@/utils/auth-utils'
import { useTranslate } from '@/utils/translate/translate-client'
import { SharingBlock } from './SharingBlock'

const USER_TICKET_IMPACT = gql`
  query userTicketImpact($input: ticketImpactInput!) {
    myTicketImpact(input: $input) {
      totalTicketsGivenThroughPif
      totalAttributions
      totalTicketsPurchasedIndividually
      totalTicketsRedeemedForFree
    }
  }
`

export interface ShareSectionProps {
  showYourImpact?: boolean
  shareUrl: string
  projectName: string
  theatricalSlug: string
  className?: string
  headerClassName?: string
  projectType?: string
}

export const ShareSection: React.FC<ShareSectionProps> = ({
  showYourImpact = false,
  shareUrl,
  projectName,
  theatricalSlug,
  className,
  headerClassName,
  projectType = 'movie',
}) => {
  const { t } = useTranslate('common')
  const { user } = useUser()
  const [shouldShowImpact, setShouldShowImpact] = useState(false)
  const [loadTicketImpact, { data }] = useLazyQuery<UserTicketImpactQuery>(USER_TICKET_IMPACT, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
  const { isLoggedIn, loading: userLoading } = useUser()
  const { loginUrl, trackLoginStarted } = useLoginUrl()
  const { isDarkMode } = useThemeContext()
  const projectLabel = projectType === 'movie' ? 'movie' : projectType === 'special' ? 'special' : 'show'

  useEffect(() => {
    showYourImpact && setShouldShowImpact(true)
  }, [showYourImpact])

  useEffect(() => {
    if (theatricalSlug && user) {
      loadTicketImpact({ variables: { input: { projectSlug: theatricalSlug } } })
    }
  }, [theatricalSlug, loadTicketImpact, user])

  const {
    totalAttributions,
    totalTicketsGivenThroughPif,
    totalTicketsPurchasedIndividually,
    totalTicketsRedeemedForFree,
  } = data?.myTicketImpact || {}
  return (
    <div
      className={classNames(
        'photon-paragraph-md mx-2 text-center font-whitney font-medium flex flex-col justify-center items-center',
        isDarkMode ? 'bg-black' : 'bg-white',
        className,
      )}
    >
      <TitleMD
        id="share-section-title"
        as={AsH2}
        weight="bold"
        className={classNames(
          'py-2',
          {
            'text-white': isDarkMode,
          },
          headerClassName,
        )}
      >
        {t('shareProject', 'Share {{name}}', { name: projectName })}
      </TitleMD>
      <ParagraphMD
        weight="normal"
        color={isDarkMode ? 'core-gray-300' : 'core-gray-700'}
        className="max-w-[600px] text-base"
      >
        {t(
          'togetherWeCanReachWorldShare',
          'Together, we reach every corner of the world. Click below to share this {{ project }} with family & friends and see the impact of your share.',
          { project: projectLabel },
        )}
      </ParagraphMD>
      <div className="pb-6 pt-3">
        <SharingBlock projectSlug={theatricalSlug} shareUrl={shareUrl} />
      </div>
      {shouldShowImpact && (
        <>
          <div className={classNames('flex flex-wrap justify-center pt-2 pb-6 ', isLoggedIn ? '' : 'text-gray-200')}>
            <TitleMD as={AsH3} weight="bold" className={isDarkMode ? 'text-white' : 'text-black'}>
              {t('yourShareImpact', 'Your Share Impact')}
            </TitleMD>
          </div>
          <div className="relative">
            <div className="relative mt-1 flex flex-wrap justify-center">
              {!isLoggedIn && !userLoading && (
                <div
                  className={classNames(
                    isDarkMode ? 'bg-black' : 'bg-white',
                    'absolute inset-0 flex justify-center items-center z-10 bg-opacity-50  backdrop-blur-xxs -m-3',
                  )}
                >
                  <InternalLink
                    href={loginUrl}
                    onClick={() => {
                      trackLoginStarted('downloadable-document-card')
                    }}
                  >
                    <Button
                      variant="white"
                      className="absolute left-1/2 top-1/2 z-20 w-full max-w-xl -translate-x-1/2 -translate-y-1/2 border-[1.5px] border-solid !border-gray-950 p-4"
                    >
                      <div>{t('signInToSeeImpactShort', 'Sign In To View Impact')}</div>
                    </Button>
                  </InternalLink>
                </div>
              )}
              <div className="flex flex-row flex-wrap rounded-xl border border-solid border-gray-200 bg-[#FAFAFA] p-4">
                <div className="mb-0 flex basis-1/4 flex-col items-center justify-start lg:mb-2 lg:basis-1/2 xl:mb-0 xl:basis-1/4">
                  <PointerIcon size={20} />
                  <div className="py-1 text-lg font-bold">{totalAttributions}</div>
                  <div className="px-1 text-center text-[10px] leading-[13px]">{t('linkClicks', 'Link Clicks')}</div>
                </div>
                <div className="mb-0 flex basis-1/4 flex-col items-center justify-start lg:mb-2 lg:basis-1/2 xl:mb-0 xl:basis-1/4">
                  <TicketIcon color="black" size={20} />
                  <div className="py-1 text-lg font-bold">{totalTicketsRedeemedForFree}</div>
                  <div className="px-1 text-center text-[10px] leading-[13px]">
                    {t('freeTicketsClaimed', 'Free Tickets Claimed')}
                  </div>
                </div>
                <div className="mb-0 flex basis-1/4 flex-col items-center justify-start lg:mb-2 lg:basis-1/2 xl:mb-0 xl:basis-1/4">
                  <PayItForwardOutlineIcon color="black" size={20} />
                  <div className="py-1 text-lg font-bold">{totalTicketsGivenThroughPif}</div>
                  <div className="px-1 text-center text-[10px] leading-[13px]">
                    {t('ticketsGifted', 'Tickets Gifted')}
                  </div>
                </div>
                <div className="mb-0 flex basis-1/4 flex-col items-center justify-start lg:mb-2 lg:basis-1/2 xl:mb-0 xl:basis-1/4">
                  <MovieSpecialIcon size={20} />
                  <div className="py-1 text-lg font-bold">{totalTicketsPurchasedIndividually}</div>
                  <div className="px-1 text-center text-[10px] leading-[13px]">
                    {t('ticketsPurchased', 'Tickets Purchased')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
